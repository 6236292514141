<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="text-center">
          <v-card-title>
            <h1 class="display-1">404</h1>
          </v-card-title>
          <v-card-text>
            <h2 class="headline">Page Not Found</h2>
            <p class="subheading">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <v-btn color="primary" to="/">Back to Home</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
  .headline {
    margin-top: 20px;
  }

  .subheading {
    margin-bottom: 20px;
  }
</style>