<template>
  <div>
    <canvas :id="canvasId" width="130px" height="130px"></canvas>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Chart from 'chart.js';

  export default defineComponent({
    components: {
        Chart
    },
    props: {
      canvasId: {
      type: String,
      required: true
    },
    chartType: {
      type: Number,
      required: true
    },
    },
    data: {
        option: {
          legend: {
            display: false,
          },
          responsive: false
        },
        data: null,
    },
    methods: {
        renderGraph(sub, total){
            if(this.chartType == 1){
                this.data = {
                  labels: [
                      'Sub-Total',
                      'Total'
                  ],
                  datasets: [
                      {
                        backgroundColor: ['#ff7518', '#D3D3D3'],
                        data: [sub, total]
                      }
                  ],
                }
            }

            if(this.chartType == 2){
                this.data = {
                  labels: [
                      'Sub-Total',
                      'Total'
                  ],
                  datasets: [
                      {
                        backgroundColor: ['#00A36C', '#D3D3D3'],
                        data: [sub, total]
                      }
                  ],
                }
            }

            if(this.chartType == 3){
                this.data = {
                  labels: [
                      'Sub-Total',
                      'Total'
                  ],
                  datasets: [
                      {
                        backgroundColor: ['#FF0000', '#D3D3D3'],
                        data: [sub, total]
                      }
                  ],
                }
            }

            const ctx = document.getElementById(this.canvasId).getContext('2d')
            new Chart(ctx, {
                type: 'doughnut',
                data: this.data,
                options: this.option,
            });
            
        }
    }
  })
</script>

<style  scoped>

</style>