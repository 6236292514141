<template>
    <v-container class="toggle animated" fluid>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
              v-model="search"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              density="comfortable"
              solo
              hide-details
              class="rounded-lg"
              color="white"
              style="font-family: Saira Condensed, sans-serif;"></v-text-field>
        </v-col>
        <!-- <v-col class="text-right" cols="12" md="8">
            <v-btn
            @click="showAddDialog()"
            prepend-icon="mdi-plus"
            class="mt-2 rounded-lg"
            color="primary"
            height="40px"
            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                Add Product
            </v-btn>
        </v-col> -->
        <v-col cols="12">
          <v-card class="dt-container mt-2">    
              <v-data-table
                :mobile="isMobileOrTablet()"
                :headers="headers"
                :items="data"
                :search="search"
                :loading="loading"
                class="text-subtitle-1"
                style="font-family: Saira Condensed, sans-serif;">
              <template v-slot:loading>
                <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip v-if="item.status == 'active'" color="green">
                    <span>Active</span>
                </v-chip>
                <v-chip v-if="item.status == 'inactive'" color="red">
                    <span>Inactive</span>
                </v-chip>
            </template>
              <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                      @click="showEditDialog(item)"
                      class="me-2"
                      size="small"
                  >
                      mdi-pencil
                  </v-icon>
                  <v-icon
                      size="small"
                      @click="showDeleteDialog(item)"
                  >
                      mdi-delete
                  </v-icon>
              </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
  </v-container>

  <v-dialog v-model="deleteDialog" width="450" persistent>
        <v-card color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
            <v-card-text class="font-weight-bold">
                Are you sure you want to delete this product ?
                <br><br>{{ itemToDelete.title }}
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="deleteDialog = false" variant="outlined">
                Cancel
            </v-btn>
            <v-btn @click="deleteItem()" color="red" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

  <ProductDialog ref="ProductDialog" v-on:refresh="init()"></ProductDialog>

  <!-- <CSVDialog ref="CSVDialog" v-on:refresh="init()"></CSVDialog> -->
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import ProductDialog from '../Dialogs/ProductDialog.vue';


export default defineComponent({
    components: {
        ProductDialog
    },
    data(){
        return {
            data: [],
            deleteDialog: false,
            itemToDelete: null,
            search: null,
            loading: false,
            headers: [
                { title: 'Title', align: 'start', sortable: true, key: 'title' },
                { title: 'Description', align: 'start', sortable: true, key: 'description' },
                { title: 'Points', align: 'start', sortable: true, key: 'requiredPoints' },
                { title: 'Status', align: 'start', sortable: true, key: 'status' },
                { title: 'Actions', align: 'end', sortable: false, key: 'action' },
            ],
        }
    },
    methods: {
        init(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            if(token){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/store/products',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.products
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }
            else{
                localStorage.removeItem("vuex");
                localStorage.removeItem("ballyboy-admin-accesstoken");
                window.location.reload();
            }
        },
        showDeleteDialog(item){
            this.itemToDelete = item
            this.deleteDialog = true
        },
        deleteItem(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.delete(process.env.VUE_APP_SERVER_URL + '/api/admin/store/product/delete?id=' + this.itemToDelete._id,
            {
                headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                },
            }
            ).then((res)=>{
                if(res.data){
                    this.init()
                    this.deleteDialog = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        showAddDialog(){
            this.$refs.ProductDialog.initAdd()
        },
        showEditDialog(data){
            this.$refs.ProductDialog.initUpdate(data)
        },
    },
    mounted(){
        // this.init()
    }
}) 
</script>

<style scoped>

</style>