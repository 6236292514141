<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    
})
</script>

<style scoped>

</style>