export const WHEEL_BG = [
    '#121212',
    '#808080',
    '#FFFFFF',
];

export const WHEEL_COLOR = [
    '#FFFFFF',
    '#FFFFFF',
    '#121212',
];

export const WHEEL_DURATION = 10000

export const WHEEL_ANIMATION = 'cubic-bezier(0.36, 0.95, 0.64, 1)'

export const ANGLE_BASE = 15