// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import colors from 'vuetify/util/colors'

export default createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#373f49',
          secondary: '#1294F0',
          background: '#121212',
        },
      },
    },
  },
}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
                             //test
)
