<template>
    <v-dialog v-model="dialog" fullscreen>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #373f49 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn flat icon color="primary" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text style="overflow-y: auto; background-color: #272C30 !important;">
                <!-- Please input the roullet here -->
                <v-row dense>
                    <v-col cols="12">
                        <div id="wheel-of-names-bg" class="py-10 !flex flex justify-center items-center" style="display: flex; flex-direction: column; align-items: center;">
                            <!-- canvas -->
                            <FortuneWheel
                                style="width: 500px; max-width: 100%"
                                :verify="canvasVerify"
                                :canvas="canvasOptions"
                                :prizes="prizesCanvas"
                                :useWeight="true"
                                :duration="duration"
                                :timingFun="animation"
                                :angleBase="angleBase"
                                @rotateStart="onCanvasRotateStart"
                                @rotateEnd="onRotateEnd"
                                :key="prizesCanvas"
                            />
                            <div>
                                <div class="text-h6 font-weight-bold mt-6" style="font-family: Saira Condensed, sans-serif; color: white;">Click the center to spin</div>
                            </div>
                            <ModalElement :open="open" :prize="prize" @delete="deleteItem" @close="open=false"/>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-row dense cols="12" md="6">
                            <v-col>
                                <div class="text-h6 mt-4" style="font-family: Saira Condensed, sans-serif; color: white;">List of Winners:</div>
                            </v-col>
                            <v-col class="text-right" cols="12" md="6">
                                <v-btn
                                @click="endDialog = true"
                                prepend-icon="mdi-alpha-x"
                                class="mt-2 rounded-lg"
                                color="primary"
                                height="40px"
                                elevation="2" style="font-family: Saira Condensed, sans-serif;">
                                    End Giveaway
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-card class="dt-container mt-2">    
                            <v-data-table
                                :mobile="isMobileOrTablet()"
                                :disable-sort="false"
                                :headers="headers"
                                :items="data"
                                :loading="loading"
                                class="text-subtitle-1"
                                style="font-family: Saira Condensed, sans-serif;">
                            <template v-slot:loading>
                                <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                            </template>
                            <template v-slot:[`item.user.createdAt`]="{ item }">
                                <span>{{ formatDateTime2(item.user.createdAt) }}</span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                    <v-icon
                                    @click="showAddDialog(item)"
                                    class="me-2"
                                    size="small"
                                    >
                                        mdi-database-plus
                                    </v-icon>
                            </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="endDialog" width="450" persistent>
            <v-card color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
                <v-card-text class="font-weight-bold">
                    Are you sure you want to end this giveaway? 
                    Once end you cannot spin the wheels again.
                </v-card-text>
                <template v-slot:actions>
                <v-spacer></v-spacer>

                <v-btn @click="endDialog = false" variant="outlined">
                    Cancel
                </v-btn>
                <v-btn @click="endGiveway(this.giveawayData)" color="red" variant="elevated">
                    Confirm
                </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import FortuneWheel from "vue-fortune-wheel";
import "vue-fortune-wheel/style.css";
import { ANGLE_BASE, WHEEL_ANIMATION, WHEEL_BG, WHEEL_COLOR, WHEEL_DURATION } from '../../../const';
import ModalElement from '@/components/Dialogs/ModalElement.vue';

export default defineComponent({
    emits: ["end"],
    components: {
        FortuneWheel,
        ModalElement,
    },
    data () {
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            loading: false,
            giveawayData: [],
            data: [],
            itemToEnd: [],
            endDialog: false,
            canvasVerify: false,
            wheelNames: [],
            open: false,
            prize: '',
            prizesCanvas: [
                {
                    id: 1, //* The unique id of each prize, an integer greater than 0
                    name: "", // Prize name, display value when type is canvas (this parameter is not needed when type is image)
                    value: "", //* Prize value, return value after spinning
                    bgColor: "#121212", // Background color (no need for this parameter when type is image)
                    color: "white", // Font color (this parameter is not required when type is image)
                    useWeight: 1,
                },
            ],
            verifyDuration: 10,
            canvasOptions: {
                textLength: 40,
                btnWidth: 60,
                borderColor: "#CC6E00",
                borderWidth: 1,
                lineHeight: 0,
                btnText: "",
                fontSize: 16,
                textDirection: 'vertical'
            },
            colors: WHEEL_BG,
            textColors: WHEEL_COLOR,
            names: [],
            duration: WHEEL_DURATION,
            animation: WHEEL_ANIMATION,
            angleBase: ANGLE_BASE,

            headers: [
                { title: 'Users', align: 'start', sortable: true, key: 'user.username' },
                { title: 'Kick Username', align: 'start', sortable: true, key: 'user.kick' },
                { title: 'Metaspins', align: 'start', sortable: true, key: 'user.metaspins' },
                { title: 'Packdraw', align: 'start', sortable: true, key: 'user.packdraw' },
                { title: 'Twitter', align: 'start', sortable: true, key: 'user.twitter' },
                { title: 'Winning Time', align: 'end', sortable: true, key: 'user.createdAt' },
            ],
        }
    },
    methods: {
        initWheels(data){
            this.dialog = true
            this.main_title = 'Spin the wheel for "' + data.title + '" Entries'
            this.sub_title = 'Ballyboy Wheel of Names'
            this.giveawayData = data
            this.getNamesOnWheels(data)
            this.getNamesOnWinners(data)
        },
        getNamesOnWheels(data){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/' + data._id + '/users?isWinner=false',
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res){
                    this.names = res.data.giveawayUsers
                    this.changeWheelContent()
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        getNamesOnWinners(data){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            this.loading = true
            axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/' + data._id + '/users?isWinner=true',
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res){
                    this.data = res.data.giveawayUsers
                    this.loading = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        getRandomInt(max) {
            let colorRank = 0
            if (max % 1 == 0) colorRank = 0
            if (max % 2 == 0) colorRank = 1
            if (max % 3 == 0) colorRank = 2
            // if (max % 4 == 0) colorRank = 3

            return colorRank
        },
        changeWheelContent () {
            let prizes = [];
            this.names?.map((data, index) => {
                prizes.push(
                {
                    id: index, //* The unique id of each prize, an integer greater than 0
                    name: data.user.username, // Prize name, display value when type is canvas (this parameter is not needed when type is image)
                    value: data, //* Prize value, return value after spinning
                    bgColor: this.colors[this.getRandomInt(index + 1)], // Background color (no need for this parameter when type is image)
                    color: this.textColors[this.getRandomInt(index + 1)], // Font color (this parameter is not required when type is image)
                    // probability: 100/names.length, //* Probability, up to 4 decimal places (the sum of the probabilities of all prizes
                    weight: 1,
                }
                )
            });

            this.prizesCanvas = prizes
        },
        endGiveway(data){
            this.$emit('end', data)
            this.endDialog = false
            this.dialog = false
        },
        deleteItem (prize) {
            this.open = false

            console.log(prize)

            const formData = {
                userId: prize.user._id,
                giveawayId: this.giveawayData._id
            }

            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/winner/set', formData,
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res){
                    this.getNamesOnWheels(this.giveawayData)
                    this.getNamesOnWinners(this.giveawayData)
                    this.changeWheelContent()
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        // Simulate the request back-end interface
        testRequest(verified, duration) {
            // verified: whether to pass the verification, duration: delay time
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve(verified);
                }, duration);
            });
        },
        onCanvasRotateStart(rotate) {
            if (this.canvasVerify) {
                const verified = true; // true: the test passed the verification, false: the test failed the verification
                this.testRequest(verified, verifyDuration * 1000).then((verifiedRes) => {
                if (verifiedRes) {
                    rotate(); // Call the callback, start spinning
                    this.canvasVerify = false; // Turn off verification mode
                } else {
                    console.error("Failed verification");
                }
                });
                return;
            }
        },
        onRotateEnd(item) {
            this.prize = item?.value
            this.open = true
        },
    }
})
</script>

<style>
    .fw-btn__btn {
        background: linear-gradient(to right, #CC6E00, #FFE7CB 50%, #CC6E00) !important;
    }

    .fw-btn__btn::before {
        border-bottom: 18px #CC6E00 solid !important;
        opacity: .5;
    }
</style>