<template>
    <v-dialog v-model="dialog" width="1300" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #272C30 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 0px);">
                <v-form ref="form" class="mt-2">
                    <v-row class="mt-4" dense style="font-family: Saira Condensed, sans-serif;">
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="type"
                                :on-change="changeType()"
                                label="Type"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                                :items="typeData"
                                item-title="title"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="search"
                                variant="outlined"
                                prepend-inner-icon="mdi-magnify"
                                label="Search"
                                density="comfortable"
                                solo
                                hide-details
                                class="rounded-lg"
                                clearable
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;">
                            </v-text-field>
                        </v-col>
                        <v-col v-if="type == 'all'" cols="12" md="3">
                            <v-btn
                                @click="refreshEntries()"
                                prepend-icon="mdi-refresh"
                                class="mt-1 rounded-lg"
                                color="primary"
                                height="40px"
                                elevation="2" style="font-family: Saira Condensed, sans-serif;">
                                Refresh
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="dt-container mt-2">    
                                <v-data-table
                                    :mobile="isMobileOrTablet()"
                                    :disable-sort="false"
                                    :headers="headers"
                                    :items="data"
                                    :loading="loading"
                                    :search="search"
                                    class="text-subtitle-1"
                                    style="font-family: Saira Condensed, sans-serif;">
                                <template v-slot:loading>
                                    <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                                    <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                                </template>
                                <template v-slot:[`item.user.createdAt`]="{ item }">
                                    <span>{{ formatDateTime2(item.user.createdAt) }}</span>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                        <v-icon
                                        @click="showAddDialog(item)"
                                        class="me-2"
                                        size="small"
                                        >
                                            mdi-database-plus
                                        </v-icon>
                                </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <!-- <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            action: null,
            loading: false,

            search: null,

            type: 'all',
            giveaway: [],
            data: [],

            headers: [
                { title: 'User', align: 'start', sortable: true, key: 'user.username' },
                { title: 'Kick Username', align: 'start', sortable: true, key: 'user.kick' },
                { title: 'Metaspins', align: 'start', sortable: true, key: 'user.metaspins' },
                { title: 'Chicken.GG', align: 'start', sortable: true, key: 'user.chickengg' },
                { title: 'Packdraw', align: 'start', sortable: true, key: 'user.packdraw' },
                { title: 'Twitter', align: 'start', sortable: true, key: 'user.twitter' },
                { title: 'Created at', align: 'end', sortable: true, key: 'user.createdAt' },
            ],
            
            snackbar: false,
            snackText: null,

            typeData: [
                { title: 'All', value: 'all' },
                { title: 'Winners', value: 'winners' },
            ],
        }
     },
     methods: {
        initView(data){
            this.dialog = true
            this.main_title = 'View ' + data.title + ' Entries'
            this.sub_title = 'Ballyboy entries and information'
            this.action = 'view'

            this.giveaway = data
            
            if(data.status == 'started'){
                this.type = 'all'
            }
            else if(data.status == 'ended'){
                this.type = 'winners'
            }
        },
        changeType(){
           const token = localStorage.getItem('ballyboy-admin-accesstoken')
           if(this.type == 'all'){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/' + this.giveaway._id + '/users',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.giveawayUsers
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
           }
           else if(this.type == 'winners'){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/' + this.giveaway._id + '/users?isWinner=true',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.giveawayUsers
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
           }
        },
        refreshEntries(){
            this.changeType()
        },
        closeDialog(){
            this.dialog = false
        }
     },
})
</script>

<style scoped>

</style>