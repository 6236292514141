<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #373f49 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn :disabled="loading" flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <v-form ref="form" class="mt-4">
                    <v-row dense style="font-family: Saira Condensed, sans-serif;">
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Username:</div>
                            <div>{{ $store.state.user.username }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Note: <span class="font-weight-regular">Changing your password may require you to re-login</span></div>
                        </v-col>
                        <v-col v-if="changePassword == false" @click="changePassword = true" cols="12">
                            <v-btn color="red">Change Password</v-btn>
                        </v-col>
                        <v-col v-if="changePassword == true"  cols="12">
                            <v-text-field
                                v-model="oldPassword"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Old Password"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="changePassword == true"  cols="12">
                            <v-text-field
                                v-model="newPassword"
                                :rules="[formRules.required, formRules.noSpacesOnly, formRules.maxLength]"
                                label="New Password"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="changePassword == true"  cols="12">
                            <v-text-field
                                v-model="confirmPassword"
                                :rules="[formRules.required, formRules.noSpacesOnly, formRules.maxLength]"
                                label="Confirm New Password"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="changePassword == true" class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        :color="snackColor"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["logout"],
     data(){
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            action: null,
            loading: false,
            
            snackbar: false,
            snackText: null,
            snackColor: null,

            oldPassword: null,
            newPassword: null,
            confirmPassword: null,

            changePassword: false,

            formRules: {
                required: value => !!value || 'Required',
                minValue: value => (value && value != 0) || 'Must be greater than 0',
                maxLength: value => (value && value.toString().length >= 8) || 'Must be less than or equal to 8 characters',
                noSpacesOnly: value => (value && value.trim().length > 0) || 'This cannot be just spaces',
            }
        }
     },
     methods: {
        initView(){
            this.dialog = true
            this.main_title = 'View Profile'
            this.sub_title = 'User Profile and Information'

            this.changePassword = false
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                const token = localStorage.getItem('ballyboy-admin-accesstoken')
                
                let formData = {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                    confirmPassword: this.confirmPassword,
                }

                this.loading = true
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/user/changePassword', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if (res.data) {
                            this.$emit('logout')
                            this.loading = false
                            this.snackText = 'Password Change Successfully .. Logging out..'
                            this.snackbar = true
                            this.snackColor = 'secondary'
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-admin-accesstoken");
                                    window.location.reload();
                            }
                            if (error.response.status === 400){
                                this.snackText = 'Old password is not valid'
                                this.snackbar = true
                                this.snackColor = 'red'
                                this.loading = false
                            }
                            if (error.response.status === 422){
                                this.snackText = 'New and Confirm password do not match'
                                this.snackbar = true
                                this.snackColor = 'red'
                                this.loading = false
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>