<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="props.open"
      max-width="400"
      persistent
    >
      <v-card
        :text="prize.user.username"
        color="primary"
        title="Congratulations!"
        style="font-family: Saira Condensed, sans-serif;"
      >
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn value="flat" @click="emit('close')" style="font-family: Saira Condensed, sans-serif;">
            Close and Don't save
          </v-btn>
          <v-btn variant="flat" color="secondary" class="text-red-darken-1" @click="emit('delete', prize)" style="font-family: Saira Condensed, sans-serif;">
            Remove and Save
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup>
  // eslint-disable-next-line
  const props = defineProps(['open', 'prize'])
  // eslint-disable-next-line
  const emit = defineEmits(['delete', 'prize'])
</script>
