<template>
    <v-dialog v-model="dialog" fullscreen>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #272C30 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 0px);">
                <v-form ref="form" class="mt-2">
                    <v-row class="mt-4" dense style="font-family: Saira Condensed, sans-serif;">
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Leaderboard Date:</div>
                            <div>{{ formatDate(data.startDate) }} - {{ formatDate(data.endDate) }}</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Leaderboard Type:</div>
                            <div v-if="data.leaderboardType == 'metaspins'">Metaspins</div>
                            <div v-if="data.leaderboardType == 'chicken.gg'">Chicken.gg</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Points Status:</div>
                            <div>{{ data.pointsStatus }}</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Points Posted By:</div>
                            <div>{{ data.pointsPostedBy.username }}</div>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="dt-container mt-2">    
                                <v-data-table
                                    :mobile="isMobileOrTablet()"
                                    :disable-sort="false"
                                    :headers="headers"
                                    :items="winners"
                                    :loading="loading"
                                    class="text-subtitle-1"
                                    style="font-family: Saira Condensed, sans-serif;">
                                <template v-slot:loading>
                                    <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                                    <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                                </template>
                                <template v-slot:[`item.wagerAmount`]="{ item }">
                                    <span>{{ item.wagerAmount.toFixed(2) }}</span>
                                </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <span v-if="item.status == 'Not Posted'">
                                        <v-chip color="orange">
                                            Not Posted
                                        </v-chip>
                                    </span>
                                    <span v-if="item.status == 'Posted'">
                                        <v-chip color="green">
                                            Posted
                                        </v-chip>
                                    </span>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                        <v-icon
                                        @click="showAddDialog(item)"
                                        class="me-2"
                                        size="small"
                                        >
                                            mdi-database-plus
                                        </v-icon>
                                </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <!-- <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            action: null,
            loading: false,

            headers: [
                { title: 'Rank', align: 'start', sortable: true, key: 'rank' },
                { title: 'Username', align: 'start', sortable: true, key: 'username' },
                { title: 'Wagered Amount', align: 'start', sortable: true, key: 'wagerAmount' },
                { title: 'Points Status', align: 'start', sortable: true, key: 'status' },
                { title: 'Error', align: 'end', sortable: false, key: 'error' },
            ],
            
            snackbar: false,
            snackText: null,

            id: null,
            data: [],
            winners: [],
            status: null,

            statusData: [
                { title: 'Pending', value: 'pending' },
                { title: 'Inprogress', value: 'inprogress' },
                { title: 'Completed', value: 'completed' },
                { title: 'Declined', value: 'declined' },
            ],

            formRules: {
                required: value => !!value || 'Required',
                minValue: value => (value && value != 0) || 'Must be greater than 0',
                maxLength: value => (value && value.toString().length <= 10) || 'Must be less than or equal to 10 characters',
                noSpacesOnly: value => (value && value.trim().length > 0) || 'This cannot be just spaces',
            }
        }
     },
     methods: {
        initView(data){
            this.dialog = true
            this.main_title = 'View Points Distribution'
            this.sub_title = 'Ballyboy leaderboard points distribution information'
            this.action = 'view'

            this.id = data._id
            this.data = data
            this.winners = data.winners
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                const token = localStorage.getItem('ballyboy-admin-accesstoken')
                
                let formData = {
                    status: this.status
                }

                this.loading = true
                if(this.action == 'view'){
                    axios.patch(process.env.VUE_APP_SERVER_URL + '/api/admin/store/purchase/status/' + this.id, formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.loading = false
                            this.snackText = 'Purchased status updated successfully'
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>