<template>
    <v-container class="toggle animated" fluid>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="search"
                    @keyup="handleSearching()"
                    variant="outlined"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    density="comfortable"
                    solo
                    hide-details
                    class="rounded-lg"
                    color="white"
                    clearable
                    style="font-family: Saira Condensed, sans-serif;">
                </v-text-field>
            </v-col>
            <v-col class="text-right" cols="12" md="8">
                <v-btn
                @click="showAddDialog()"
                prepend-icon="mdi-plus"
                class="mt-2 rounded-lg"
                color="primary"
                height="40px"
                elevation="2" style="font-family: Saira Condensed, sans-serif;">
                    Create Giveaways
                </v-btn>
            </v-col>
            <v-col cols="12" class="mt-2">
                <v-col v-if="loading" cols="12">
                    <v-progress-linear indeterminate color="secondary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                </v-col>
                <v-row v-if="data.length == 0 && loading == false">
                    <v-col cols="12" class="text-center font-weight-bold mt-8">
                        No giveaways to show
                    </v-col>
                </v-row>
                <v-row v-if="loading == false && data.length > 0" dense>
                    <v-col v-for="(data, index) in data" :key="index" cols="12" md="3">
                        <v-card elevation="3" color="primary" class="pa-3">
                            <v-row dense>
                                <v-col cols="12">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Title:</div>
                                    <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ data.title }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Code:</div>
                                    <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ data.code }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Created by:</div>
                                    <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ data.createdBy.username }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Started by:</div>
                                    <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ !data.startedBy ? '---' : data.startedBy.username }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Stopped by:</div>
                                    <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ !data.stoppedBy ? '---' : data.stoppedBy.username }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Status:</div>
                                    <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">
                                        <v-chip v-if="data.status == 'active'" color="blue" variant="flat">Waiting to start</v-chip>
                                        <v-chip v-if="data.status == 'started'" color="green" variant="flat">Started</v-chip>
                                        <v-chip v-if="data.status == 'stopped'" color="red" variant="flat">Stopped</v-chip>
                                        <v-chip v-if="data.status == 'ended'" color="gray" variant="flat">Ended</v-chip>
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Actions</div>
                                    <div class="d-flex flex-row ga-2">
                                        <v-btn @click="showEditDialog(data)" v-if="data.status == 'active' || data.status == 'started'" color="primary"  size="small" elevation="4" style="font-family: Saira Condensed, sans-serif;">Edit</v-btn>
                                        <v-btn @click="showDeleteDialog(data)" v-if="data.status == 'active' || data.status == 'ended'" color="primary"  size="small" elevation="4" style="font-family: Saira Condensed, sans-serif;">Delete</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="d-flex flex-column mt-4 ga-3">
                                <v-btn @click="showStartDialog(data)" v-if="data.status == 'active'" elevation="4" class="text-subtitle-2" prepend-icon="mdi-play" color="primary" style="font-family: Oxanium, sans-serif;">
                                    Start Entries
                                </v-btn>
                                <v-btn @click="showStopDialog(data)" v-if="data.status == 'started'" elevation="4" class="text-subtitle-2" prepend-icon="mdi-stop" color="primary" style="font-family: Oxanium, sans-serif;">
                                    Stop Entries
                                </v-btn>
                                <v-btn @click="viewEntries(data)" v-if="data.status == 'started' || data.status == 'ended'" elevation="4" prepend-icon="mdi-eye" class="text-subtitle-2" color="primary" style="font-family: Oxanium, sans-serif;">
                                     {{ data.status == 'started' ? 'View Entries' : 'View Winners' }}
                                </v-btn>
                                <v-btn v-if="data.status == 'stopped'" @click="showWheels(data)" elevation="4" prepend-icon="mdi-dharmachakra" class="text-subtitle-2" color="primary" style="font-family: Oxanium, sans-serif;">
                                    Spin the Wheel
                                </v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="startDialog" width="450" persistent>
        <v-card color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
            <v-card-text class="font-weight-bold">
                Are you sure you want to start collecting entries for this giveaway? 
                Once started you cannot edit the code.
                <br><br>"{{ itemToStart.title }}"
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="startDialog = false" variant="outlined">
                Cancel
            </v-btn>
            <v-btn @click="changeGiveawayAction('start', itemToStart._id); startDialog = false" color="blue" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <v-dialog v-model="stopDialog" width="450" persistent>
        <v-card color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
            <v-card-text class="font-weight-bold">
                Are you sure you want to stop collecting entries for this giveaway? 
                Once stopped you cannot start it again.
                <br><br>"{{ itemToStop.title }}"
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="stopDialog = false" variant="outlined">
                Cancel
            </v-btn>
            <v-btn @click="changeGiveawayAction('stop', itemToStop._id); stopDialog = false" color="red" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="450" persistent>
        <v-card color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
            <v-card-text class="font-weight-bold">
                Are you sure you want to delete this giveaway? 
                Once delete you cannot view this again.
                <br><br>"{{ itemToDelete.title }}"
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="deleteDialog = false" variant="outlined">
                Cancel
            </v-btn>
            <v-btn @click="deleteGiveway(itemToDelete)" color="red" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackBar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>

    <WheelOfNamesDialog ref="WheelOfNamesDialog" @end="endGiveaway"/>

    <GiveawaysDialog ref="GiveawaysDialog" v-on:refresh="init()"/>

    <GiveawayViewEntries ref="GiveawayViewEntries"/>

</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import WheelOfNamesDialog from '../Dialogs/WheelOfNamesDialog.vue';
import GiveawaysDialog from '../Dialogs/GiveawaysDialog.vue';
import GiveawayViewEntries from '../Dialogs/Views/GiveawayViewEntries.vue';

export default defineComponent({
    components: {
        WheelOfNamesDialog,
        GiveawaysDialog,
        GiveawayViewEntries
    },
    data(){
        return{
            search: null,
            data: [],
            savedData: [],
            loading: false,

            stopDialog: false,
            startDialog: false,
            deleteDialog: false,
            itemToStart: [],
            itemToStop: [],
            itemToDelete: [],

            snackBar: false,
            snackText: null
        }
    },
    methods: {
        init(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            if(token){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.giveaways
                        this.savedData = res.data.giveaways
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }
            else{
                localStorage.removeItem("vuex");
                localStorage.removeItem("ballyboy-admin-accesstoken");
                window.location.reload();
            }
        },
        changeGiveawayAction(action, giveawayId){
            const formData = {
                action: action,
                giveawayId: giveawayId
            }
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/action', formData,
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res.data){
                    this.snackBar = true
                    this.snackText = 'Giveaway Status Succesfully Changed'
                    this.init()
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        deleteGiveway(data){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.delete(process.env.VUE_APP_SERVER_URL + '/api/admin/giveaways/delete/' + data._id,
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res.data){
                    this.snackBar = true
                    this.snackText = 'Giveaway Succesfully Deleted'
                    this.init()
                    this.deleteDialog = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        handleSearching() {
        if (this.search == "" || !this.search) {
            this.init();
        }
        else {
            this.data = this.savedData.filter(
            (data) =>
                data.title
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 
            );
          }
        },
        endGiveaway(data){
            this.changeGiveawayAction('end', data._id)
        },
        viewEntries(data){
            this.$refs.GiveawayViewEntries.initView(data)
        },
        showStartDialog(data){
            this.itemToStart = data
            this.startDialog = true
        },
        showStopDialog(data){
            this.itemToStop = data
            this.stopDialog = true
        },
        showDeleteDialog(data){
            this.itemToDelete = data
            this.deleteDialog = true
        },
        showWheels(data){
            this.$refs.WheelOfNamesDialog.initWheels(data)
        },
        showAddDialog(){
            this.$refs.GiveawaysDialog.initAdd()
        },
        showEditDialog(data){
            this.$refs.GiveawaysDialog.initEdit(data)
        }
    },
    mounted(){
        this.init()
    }
})
</script>

<style scoped>

</style>