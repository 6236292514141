<template>
    <v-container class="toggle animated" fluid>
      <v-row>
      <v-col cols="12" md="4">
        <v-card elevation="3" color="primary">
          <v-card-title style="font-family: Saira Condensed, sans-serif;">Graph 1</v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="6">
                <DonutChart ref="DonutChart1" :canvasId="'myChart1'" :chartType="1"></DonutChart>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="text-h3 font-weight-bold mr-4">10</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="3" color="primary">
          <v-card-title style="font-family: Saira Condensed, sans-serif;">Graph 2</v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="6">
                <DonutChart ref="DonutChart2" :canvasId="'myChart2'" :chartType="2"></DonutChart>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="text-h3 font-weight-bold mr-4">6</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="3" color="primary">
          <v-card-title style="font-family: Saira Condensed, sans-serif;">Graph 3</v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="6">
                <DonutChart ref="DonutChart3" :canvasId="'myChart3'" :chartType="3"></DonutChart>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="text-h3 font-weight-bold mr-4">15</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col cols="12">
        <v-card elevation="3" height="400">
          <v-card-title>Dropped students per school year</v-card-title>
          <v-card-text>
            <BarChart ref="BarChart1" :canvasId="'myChart4'"></BarChart>
        </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import DonutChart from '../Charts/DonutChart.vue';

export default defineComponent({
    name: 'Dashboard',
    components: {
        DonutChart,
    },
    data(){
        return {
            data: [],
        }
    },
    methods: {
        init(){
            this.$refs.DonutChart1.renderGraph(10,2)
            this.$refs.DonutChart2.renderGraph(6,15)
            this.$refs.DonutChart3.renderGraph(15,20)
        },
        
    },
    mounted(){
      this.init()
    }
})
   
       
    
</script>

<style scoped>

</style>